@import './vars.scss';

.card {
    flex: 1;
    background: rgba(15, 3, 50, 0.55);
    //margin: 20px 25px;
    margin: 20px 1.65vw;
    //height: 100%;
    color: white;
    min-width: 315px;
    max-width: 25rem; // 400px, TODO: try 27rem
    // max-width: 420px;
    //max-width: calc((100vw - 5.2vw + 5.2vw + 1.65 * 2 * 2) / 3);
    min-height: 100px;
    /* max-height: 723px; */
}

// making all cards equal height !
// https://stackoverflow.com/questions/56147911/setting-flexbox-items-with-same-width-and-height-based-on-the-tallest-child

.card-container {
    height: 100%;
    box-sizing: border-box;
}

.card-header {
    flex: 2;
    display: flex;
    margin-bottom: 1.2rem;
}

.card-body {
    flex: 6;
    margin: 10px auto;
    max-width: 80%;
}

.card-body /*p*/ {
    font-family: $textFont;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.8em;
    //line-height: 25px;
    // line-height: 24.72px;
    color: $textColor;
    mix-blend-mode: normal;
}

.card-footer {
    flex: 1;
    margin: 0px auto;
    width: 80%;
}

.card-title {
    background: #0F0332;
    border-radius: 0px 0px 1.25rem 1.25rem; // 20px
    width: 100%;
    height: 4.375rem; // 70px
    display: flex;
    justify-content: center;
}

.card-title h5 {
    font-size: 1.48rem;
    font-weight: 200;
    margin: auto 1.875rem auto 0; // 30px
    //padding-top: 0.125rem; // 2px
}

.card-title img {
    width: 1.875rem; // 30px
    height: 1.875rem; // 30px
    margin: auto 1.875rem auto 0px; 
}