.cardgroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //padding: 0 80px;
    padding: 0 calc(5.2vw - 1.65vw);
    margin-top: 1.25rem; // 20 px
    justify-content: space-around;
    align-items: stretch;
}
