@import '../index.scss';

.edu {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.edu-sep {
    margin-top: 1rem;
    border: 0.5px #D7D7D7 solid;
}

