@import './vars.scss';

.title {
    display: block;
    max-height: 128px;
    margin: auto 0 auto 8%;

    // after adding education
    padding-top: 20px;
}

.title h4 {
    margin: 0px;
    font-family: $nameFont;
    font-style: normal;
    //font-size: 24px;
    //line-height: 44px;
    font-size: 1.5rem;

    color: $nameColor;
}

.title-med, .title h5 {
    margin: 0;
    /*font-family: $nameFont;*/
    font-family: $textFont;
    //font-size: 16px;
    font-size: 1rem;
    color: $nameColor;
}


.title-small, .title h6 {
    @extend .title-med;
    font-size: 0.9rem;
}
