@import './vars.scss';

.avatar {
    border-radius: 50%;
    margin-top: 20px;
    //margin-left: 25px;
    margin-left: 10%;
    width: 5.625rem; // 90px
    height: 5.625rem; // 90px
    overflow: hidden;
    display: inline-block;
    border: 1px red;
    @media #{$mq-large} {
        // width: 7.7vw;
        // height: 7.7vw;
        // width: 6.9rem;
        // height: 6.9rem;
    }
}

// responsive square
// https://spin.atomicobject.com/2015/07/14/css-responsive-square/

.avatar img {
    width: auto;
    height: 100%;
    margin-left: 10px;
}