.bigletter::first-letter {
    font-size: 3.5em;
    line-height: 0;
}

.bigletter { 
    line-height: inherit;
    margin-top: 2.5em;
}

.pb1 {
    padding-bottom: 1rem;
}