@import './vars.scss';

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
}

.links div {
    flex: 1;
    display: flex;
    //font-family: 'Cormorant Garamond';
    font-family: $textFont;
    color: $textColor;
    text-decoration: underline;
    min-height: 36px;
    padding-right: 10px;
}

.links div:last-of-type {
    margin-right: 0px;
}

.links div img {
    width: 1.875rem; // 30px
    height: 1.875rem; // 30px
}

.links div a {
    color: $textColor;
    //font-size: 13px;
    font-size: 0.8125rem;
    margin-top: 0.3125rem; // 5 px
    margin-left: 0.4375rem // 7 px
}