
//$nameFont: "Cormorant Garamond";
//$textFont: "Cormorant Garamond";

$nameFont: "Copse";
$textFont: "Copse";

$nameColor: #E1E1E1;
$textColor: #D7D7D7;

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
$mq-small-only: "(max-width: #{$bp-small})";

$mq-larger-than-wave: "(min-width: 1512px)";

$bg-size: 400px;

$footer-height: 105px;

// Font-Size
$base-font-size: 1em;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;
