/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* Robot */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
/* Dancing Script https://fonts.google.com/specimen/Dancing+Script?preview.text=John%20Doe&preview.text_type=custom */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
/* Cormorant Garamond https://fonts.google.com/specimen/Cormorant+Garamond?preview.text=John%20Doe&preview.text_type=custom */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
/* Sansita Swashed https://fonts.google.com/specimen/Sansita+Swashed?preview.text=John%20Doe&preview.text_type=custom */
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300;400;500;600;700;800;900&display=swap');
/* Adamina */
@import url('https://fonts.googleapis.com/css2?family=Adamina&family=Roboto:wght@100;300;400&display=swap');
/* Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Adamina&family=Roboto:wght@100;300;400&display=swap');

//$nameFont: "Cormorant Garamond";
//$textFont: "Cormorant Garamond";

$nameFont: "Copse";
$textFont: "Copse";

$nameColor: #E1E1E1;
$textColor: #D7D7D7;

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
$mq-small-only: "(max-width: #{$bp-small})";

$mq-larger-than-wave: "(min-width: 1512px)";

$bg-size: 400px;

$footer-height: 105px;

// Font-Size
$base-font-size: 1em;
$base-font-size-unitless: 1;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;

html, body {
    padding: 0;
    margin: 0;  
    height: 100%;
}

body {
    background: url(oriental-tiles.png);
    /* background-size: 300px 300px; */
    /* 300px / 1440 px = 20.8% */
    /* The below fixates the ratio but it does not scale */
    /* iphone SE width: 375px */
    /* MacOS width: 1440px */
    /* 375 / 1440 = 0.26 (26% of 1440) */
    /* 1440 / 375 = 3.84 (384% of 375) */
    /* So if I have the background-size 20.8% for 1440px */
    /* It should be 20.8 * 3.84 for 375px of width  */
    /* => for each 1px of width: 
    /* 1440x = 20.8 => x = 20.8 / 1440 = 0.014*/
    /* 375y = 79.872 => y = 79.872 / 375 = 0.212992 */
    /* 300 px for 1440px => 300/1440 = 0.208 */
    /*background-size: 20.8%; */ /* for 1440px */
    /* background-size: 79.872%; for 375px */
    /* background-size: calc((100vw * 0.208)/100 * 300); */
    /* z / 1440 = 300px */
    /* z / 375 = 1152px */
    /* z = 432000 */
    /* background-size: calc(432000 / 100vw); */
    background-size: 200px;

    @media #{$mq-large} {
        // background-size: calc(0.2 * 100vw);
        background-size: calc(0.2 * 100vw);
    }
}

html {
    font-size: $base-font-size;
    line-height: $base-line-height;

    // @media #{$mq-xlarge} {
    //     font-size: $base-font-size*1.5;
    // }

    // @media #{$mq-xlarge} {
    //     font-size: $base-font-size*1.2;
    // }

    // @media #{$mq-xxlarge} {
    //     font-size: $base-font-size*1.4;
    // }

    @media (min-width: 90em) {
        // for each additional 480px in width, increase font size by 20%
        //font-size: calc(1em + (((100vw - 90em) / 30 * 0.2)));
        font-size: calc(#{$base-font-size-unitless} * (1em + ((100vw - 90em) / 30 * 0.2)));
    }
}
  
/* latin */
@font-face {
    font-family: 'Copse';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/copse/v13/11hPGpDKz1rGb3dkFEmDUq-B.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.box {
    --screensize: calc(100vw * 1);
    --variable: calc(1440px / 1440);
    height:var(--variable);
}

#root {
    min-height:100%;
    position: relative;
}

.content {
    padding-top: 10px;
    padding-bottom: $footer-height;   /* Height of the footer */
    @media #{$mq-larger-than-wave} {
        padding-bottom: calc(0.073 * 100vw); /* Height of the footer */
    }
}

.footer {
    position:absolute;
    bottom:0;
    width:100%;
    height: $footer-height;   /* Height of the footer */
    @media #{$mq-larger-than-wave} {
        height: calc(0.073 * 100vw);
    }
}


.wave {
    display: block;
    flex: 1;
    background: url('./wave-purple.svg') no-repeat;
    margin-top: 15px;
    height: calc(#{$footer-height} - 15px);
    @media #{$mq-small-only} {
        background-position: 80%;
    }
    @media #{$mq-larger-than-wave} {
        background-size: cover;
        height: calc(0.073 * 100vw - 15px);
    }   
}